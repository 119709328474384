<template>
  <div>
    <div class="Header mb-3">
      <b-row class="justify-content-between px-2">
        <h5 class="d-inline-block">Compras</h5>
        <font-awesome-icon
          icon="bars"
          class="text-white d-md-none"
          v-b-toggle.menu-mobile
        />
      </b-row>
      <b-row class="justify-content-center">
        <div class="Header-summary">
          <span class="Header-summary-title">Total de compras</span>
          <span class="Header-summary-cash mb-3"
            >R$ {{ formatMoney(total, 2, ",") }}</span
          >
        </div>
      </b-row>
    </div>

    <b-container fluid>
      <b-row class="Toolbar justify-content-between align-items-center">
        <b-col cols="4">
          <b-button class="btn-green" @click="openFilters">Filtros</b-button>
        </b-col>

        <b-col cols="8" md="4" class="text-right">
          <div class="Toolbar-search">
            <input
              type="text"
              placeholder="Código da venda ou produto"
              v-model="search"
            />
            <font-awesome-icon icon="search" />
          </div>
        </b-col>
      </b-row>

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="text-center d-none d-md-block"> Fatura </b-col>

        <b-col cols="5" md="4" class="text-center"> Produto </b-col>

        <b-col cols="2" class="text-center d-none d-md-block"> Data </b-col>

        <b-col cols="2" class="text-center d-none d-md-block"> Parcelas </b-col>

        <b-col cols="4" md="2" class="text-center"> Valor </b-col>

        <b-col cols="3" md="1" class="text-center"> Visualizar </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && sales.length > 0">
        <b-row
          class="Table-body"
          v-for="item in sales"
          :key="item.id"
          :class="{
            'is-green': item.status == 'paid',
            'is-light-blue':
              item.status == 'processing' || item.status == 'waiting_payment',
            'is-red': item.status == 'reject' || item.status == 'refused',
            'is-warning': item.status == 'refunded',
            'is-dark-blue': refundRequested(item),
          }"
        >
          <b-col cols="1" class="text-center d-none d-md-block">
            {{ item.id }}
          </b-col>

          <b-col cols="5" md="4" class="text-center">
            <div class="Table-body-title">
              {{
                item.product.name.length > maxLength
                  ? item.product.name.substr(0, maxLength) + "..."
                  : item.product.name
              }}
            </div>
            <div class="Table-body-subtitle">
              {{
                item.product.description.length > maxLength
                  ? item.product.description.substr(0, maxLength) + "..."
                  : item.product.description
              }}
            </div>
          </b-col>

          <b-col cols="2" class="text-center d-none d-md-block">
            {{ item.updated_at | date }}
          </b-col>

          <b-col cols="2" class="text-center d-none d-md-block">
            {{ item.installments }}
          </b-col>

          <b-col cols="4" md="2" class="text-center">
            R$ {{ formatMoney(item.total, 2, ",") }}
          </b-col>

          <b-col cols="3" md="1">
            <PurchasePreview>
              <template v-slot:button="{ openPanel }">
                <span
                  class="p-4 text-center Table-body-action"
                  @click="openPanel(item.id)"
                >
                  <font-awesome-icon icon="eye" />
                </span>
              </template>
            </PurchasePreview>
          </b-col>
        </b-row>
      </template>

      <Paginate
        v-if="sales.length > 0"
        :totalPages="pagination.totalPages"
        :activePage="pagination.currentPage"
        @to-page="toPage"
        @per-page="perPage"
      />

      <div
        v-if="loading && sales.length == 0"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && sales.length == 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma compra foi encontrado</p>
      </b-row>

      <!-- END BODY -->
    </b-container>

    <!-- modals -->
    <FiltersModal @filter="fetchPurchases" />
  </div>
</template>
<script>
import SaleService from "@/services/resources/SaleService";
import Paginate from "@/components/shared/Paginate";
import PurchasePreview from "@/components/shared/PurchasePreview";
import FiltersModal from "@/components/Client/Purchases/modals/filters";
import Money from "@/mixins/money";
import Utils from "@/mixins/utils";
const service = SaleService.build();

export default {
  name: "Login",
  components: {
    Paginate,
    PurchasePreview,
    FiltersModal,
  },
  data() {
    return {
      sales: [],
      search: null,
      loading: true,
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  mixins: [Utils,Money],
  watch: {
    search(value) {
      this.fetchPurchases({}, value);
    },
  },
  computed: {
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
  },
  methods: {
    refundRequested(item) {
      if (item.refund_solicitations.length > 0) {
        for (var i = 0; i < item.refund_solicitations.length; i++) {
          if (item.refund_solicitations[i].status == "REQUESTED") {
            return true;
          }
        }
      }

      return false;
    },
    openFilters() {
      this.$bvModal.show("sales-filters");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchPurchases();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchPurchases();
    },
    fetchPurchases(filters = {}, search = "") {
      this.loading = true;

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      if (search.length > 0) {
        data.search = search;
      } else {
        if (typeof filters.date_in != "undefined" && filters.date_in != null)
          data.date_in = filters.date_in;
        if (typeof filters.date_out != "undefined" && filters.date_out != null)
          data.date_out = filters.date_out;
        if (typeof filters.status != "undefined" && filters.status != null)
          data.status = filters.status;
        if (
          typeof filters.client_type != "undefined" &&
          filters.client_type != null
        )
          data.client_type = filters.client_type;
        if (
          typeof filters.client_data != "undefined" &&
          filters.client_data != null
        )
          data.client_data = filters.client_data;
      }

      service
        .search(data)
        .then((response) => {
          this.loading = false;
          this.sales = response.data.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchPurchases();
  },
};
</script>
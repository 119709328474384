<template>
<div>
  <slot name="button" :openPanel="openPanel">

  </slot>
<b-sidebar id="sidebar-purchase" width="350px" title="Fatura" backdrop-variant="dark" backdrop right shadow v-model="visible">

  <b-container fluid>

    <b-row class="panel" >
      <b-col>
        <b-overlay :show="loading">

          <b-row class="mb-4">
            <b-col class="sumary">
              <b-row class="mb-3">
                <b-col cols="4">
                  <b>{{ $t('shared.text_1996') }}</b><br /> {{ sale.id }}
                </b-col>
                <b-col cols="4">
                  <b>{{ $t('shared.text_1997') }}</b><br/>
                  <template v-if="refundRequest">
                    <span class="font-weight-bold text-primary">Reembolso solicitado</span>
                  </template>
                  <template v-else>
                    <span v-if="sale.status == 'paid'" class="font-weight-bold text-success">{{ $t('shared.text_2432') }}</span>
                    <span v-if="sale.status == 'processing'" class="font-weight-bold text-info">{{ $t('shared.text_2433') }}</span>
                    <span v-if="sale.status == 'reject'" class="font-weight-bold text-danger">{{ $t('shared.text_2434') }}</span>
                    <span v-if="sale.status == 'refunded'" class="font-weight-bold text-warning">{{ $t('shared.text_2435') }}</span>
                  </template>
                </b-col>
                <b-col cols="4">
                  <b>{{ $t('shared.text_2051') }}</b><br/> {{ sale.updated_at | date }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>{{ $t('shared.text_2027') }}</b><br/>
                  R$ {{ formatMoney(sale.total, 2, ',') }}

                </b-col>
                <b-col cols="4">
                  <b>{{ $t('shared.text_2049') }}</b><br/> 
                  {{ sale.installments }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Product -->
          <b-row class="my-4">
            <b-col>
              <h5 class="d-block">{{ $t('shared.text_2050') }}</h5>
              <b-row>
                <b-col cols="12" class="product-name">
                  <span v-if="sale.product">{{ sale.product.name }}</span>
                  <span v-else>{{ sale.invoices[0].product.name }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <b-img v-if="sale.product" :src="sale.product.images[0].path" fluid class="product-image"></b-img>
                  <b-img v-else :src="sale.invoices[0].product.images[0].path" fluid class="product-image"></b-img>
                </b-col>
                <b-col cols="7" class="product-description">
                  <span v-if="sale.product">{{ sale.product.description }}</span>
                  <span v-else>{{ sale.invoices[0].product.description }}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Options -->
          <b-row class="mt-5">
            <b-col>
              <h5 class="mb-3"> {{ $t('shared.text_2052') }} </h5>
              <b-button type="button" variant="danger" :disabled="btnRefundIsDisable || refundRequest" @click="refund">{{ $t('shared.text_2431') }}</b-button>
            </b-col>

          </b-row>


        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</b-sidebar>
</div>
</template>
<script>
import SaleService from "@/services/resources/SaleService";
import RefundService from "@/services/resources/RefundService";
import Money from '@/mixins/money';
import Utils from "@/mixins/utils";
import moment from "moment";
const serviceSale = SaleService.build();
const serviceRefund = RefundService.build();

export default {
  name: 'PurchasePreview',
  mixins: [Utils,Money],
  data () {
    return {
      loading: true,
      btnRefundIsDisable: false,
      visible: false,
      sale_id: -1,
      sale: {
        product:{
          name: null,
          description: null,
          images:[
            {path: null}
          ]
        },
        client:{
          name: null,
          email: null,
          cellphone: null
        },
        refund_solicitations: []
      }
    }
  },
  computed:{
    refundRequest(){
      for(var i = 0; i < this.sale.refund_solicitations.length; i++)
        if(this.sale.refund_solicitations[i].status == 'REQUESTED')
          return true;
      
      return false;
    }
  },
  methods: {
    openPanel(sale_id){
      this.visible = true;
      this.sale_id = sale_id;
      this.fetchSale()
    },
    refund(){

      let data = {
        sale_id: this.sale.id
      };

      serviceRefund
        .create(data)
        .then(response => {
          this.btnRefundIsDisable = true;
          this.$bvToast.toast(this.$t('shared.text_1995'), {
            title: this.$t('shared.text_2421'),
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: true
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchSale(){
      this.loading = true;

      let data = {
        id: this.sale_id
      };

      serviceSale
        .read(data)
        .then(response => {
          this.loading = false;
          this.sale = response;

        })
        .catch(err => {
          console.log(err);
        });
    },
  }

  }
</script>

<style lang="scss" scoped>
.panel{

  .sumary{
    background: rgb(240, 240, 240);
    font-size: 13px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .product-name{
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .product-image{
    width: 100%;
  }
  
  .product-description{
    font-size: 14px;
  }

}



</style>